


































































































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import { IParticipant } from '@/interface/participant';
import { IProject } from '@/interface/project';
import {GROUP, IGroup} from '@/interface/group';
import { EMAIL } from '@/interface/email';

@Component
export default class InvitationSend extends Vue {
  @PropSync('invitationTargetList') invitationCheckList: IParticipant[];
  registerGroupList: IProject[] = [];
  padding: boolean = false;
  registerGroupObjectId: string = '';
  GROUP: any = GROUP;
  emailHtml: string = '';

  constructor(props) {
    super(props);
  }

  created() {
    this.load();
  }

  async load() {
    try {
      const sendData = {
        status: "expect",
        search: '',
        page: '1',
        limitPage: '999',
        sort: '-1',
      }
      const { data } = await this.axios.get(`/project`, { params: sendData });
      const {result, projects } = data;
      if(result) this.registerGroupList = projects;
    } catch (e) {
      console.error(e);
    }
  }

  async openConfirmInvitation() {
    if (this.registerGroupObjectId == '') {
      this.$toast.error('예정 중인 조사를 선택 후 미리보기가 가능합니다.');
      return;
    }
    const { data } = await this.axios.get(
      `/mail/template/${EMAIL.TEMPLATE_TYPE.INVITATION}`
    );
    const { emailHtml } = data;
    this.emailHtml = emailHtml;
    this.$bvModal.show('invitationConfirmModal');
  }

  //초대하기 선택취소
  cancelInvitation(listId: string) {
    const findIndex = this.invitationCheckList.findIndex(
      (i) => i._id === listId
    );
    this.invitationCheckList.splice(findIndex, 1);
    if (this.invitationCheckList.length === 0) this.closeInvitationModal();
    this.$emit('callback:cancelInvitation', listId);
  }

  openInvitationModal() {
    if (this.registerGroupList.length === 1) {
      const { groups } = this.registerGroupList[0];
      if (groups && groups.length === 1) {
        const { _id } = groups[0];
        this.registerGroupObjectId = _id as string;
      } else {
        this.registerGroupObjectId = '';
      }
    } else {
      this.registerGroupObjectId = '';
    }
    this.$bvModal.show('invitationModal');
  }

  closeInvitationModal() {
    this.$bvModal.hide('invitationModal');
  }

  closeInvitationConfirmModal() {
    this.$bvModal.hide('invitationConfirmModal');
  }

  async invitationSend() {
    try {
      let projectTitle = '';
      let groupTitle = '';
      if (this.registerGroupObjectId == '') {
        this.$toast.error('예정 중인 조사를 선택 후 발송이 가능합니다.');
        return;
      }

      for (let i = 0; i < this.registerGroupList.length; i++) {
        const row = this.registerGroupList[i];
        const { groups } = row;
        if (groups) {
          const group = groups.find(
            (g) => g._id === this.registerGroupObjectId
          );
          if (group) {
            projectTitle = row.title;
            //#TODO 나중에 뭐하는거인지 파악
            groupTitle = group.title || '';
            break;
          }
        }
      }

      const ok = await this.$bvModal.msgBoxConfirm(
        `[${projectTitle} (${groupTitle})] 초대발송을 하시겠습니까?`,
        {
          title: '발송 확인',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: '발송',
          cancelTitle: '취소',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (!ok) return;
      const sendData = {
        invitationCheckList: this.invitationCheckList,
      };
      this.padding = true;
      const { data } = await this.axios.post(
        `/participant/invitation/send/${this.registerGroupObjectId}`,
        sendData
      );
      const { result } = data;
      if (result) {
        this.padding = false;
        this.$toast.success(
          `${this.$validation.replaceComma(
            this.invitationCheckList.length
          )} 건의 초대메일이 발송되었습니다.`
        );
        this.$emit('callback:listLoad');
        this.closeInvitationModal();
      }
    } catch (e) {
      console.log(e);
    }
  }

  calculatorGroupTime(length: number): string {
    const hour = Math.floor(length / 60);
    const hourStr = hour < 1 ? '' : `${hour}Hour `;
    const minute = length % 60;
    const minuteStr = minute === 0 ? '' : `${minute}Minute`;
    return hourStr + minuteStr;
  }
}
