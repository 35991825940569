









































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { IMemo, IParticipant, PARTICIPANT } from '@/interface/participant';
import { GROUP } from '@/interface/group';
import { IProject } from '@/interface/project';
import InvitationSend from '@/views/participant/components/InvitationSend.vue';
import { IOptionValue } from '@/utils/interface';
import ParticipantModal from '@/components/ParticipantModal.vue';
import TopShortcutLeft from '@/components/common/shortcut/TopShortcutLeft.vue';

interface ITempList extends IParticipant {
  profileShow?: boolean;
  projectTitles: [];
  groupTitles: [];
}

interface ISendMessageForm {
  participantObjectId: string;
  from: string;
  to: string;
  subject: string;
  contents: string;
}

@Component({
  components: {
    InvitationSend,
    ParticipantModal,
    TopShortcutLeft
  },
})
export default class List extends Vue {
  padding: boolean = false;
  pending: boolean = false;
  page: number = 1;
  totalRow: number = 0;
  limitPage: number = 10;
  search: string = '';
  PARTICIPANT: any = PARTICIPANT;
  registerGroupObjectId: string = '';
  participantArray: ITempList[] = [];
  GROUP: any = GROUP;
  categoryIndex: number = 0;
  participantCategory: string[] = [];
  categoryOptions: Array<IOptionValue> = [];
  categoryAdd: string = '';
  sort: number = -1;
  memoList: IMemo[] = [];
  selectParticipant: IParticipant = PARTICIPANT.getInitParticipant();
  invitationTargetList: IParticipant[] = [];
  registerGroupList: IProject[] = [];
  sendSmsForm: ISendMessageForm = {
    participantObjectId: '',
    from: '',
    to: '',
    subject: '',
    contents: '',
  };
  registerMemo: IMemo = { memo: '' };
  addMemoFlag: boolean = false;

  $refs!: Vue['$refs'] & {
    groupId: HTMLElement;
    listName: HTMLElement;
    listEmail: HTMLElement;
    listBirthDate: HTMLElement;
    listMobile: HTMLElement;
    uploadInvitation: any;
    invitationSend: InvitationSend;
    participantModal: ParticipantModal;
  };

  constructor() {
    super();
  }

  created() {
    this.participantCategory = this.$store.getters.getParticipantCategory;
    this.categoryOptions = this.participantCategory.map((category) => {
      return {
        text: category === 'ALL' ? '분류' : category,
        value: category === 'ALL' ? '' : category,
      };
    });
    this.participantList();
  }

  getStatusText(status) {
    return this.PARTICIPANT.INVITATION_OPTIONS.find((i) => status === i.value).text || '';
  }

  async participantList(): Promise<void> {
    this.pending = true;
    try {
      const category = this.participantCategory[this.categoryIndex];

      const sendData = {
        page: this.page,
        limitPage: this.limitPage,
        search: this.search,
        sort: this.sort,
        category,
        categoryStatus: 'participant',
      };
      const { data } = await this.axios.get(`participant`, { params: sendData });
      //const {participants, listTotal, projectList} = data;
      const { result, participants, total } = data;
      if (result) {
        this.registerGroupList = []; //projectList;
        this.totalRow = total;
        this.participantArray = [];
        this.$nextTick(() => {
          this.participantArray = participants.map((l: any) => {
            l.memos.sort((a, b) => {
              return a.regDate > b.regDate ? 1 : -1;
            });
            return { profileShow: false, ...l };
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
    this.pending = false;
  }

  replaceGender(gender: PARTICIPANT.GENDER): string {
    return gender == PARTICIPANT.GENDER.MAN ? '남자' : '여자';
  }

  replaceAge(date: string = ''): number {
    const today = new Date();
    const birthDate = new Date(date.substr(0, 4));
    return today.getFullYear() - birthDate.getFullYear() + 1;
  }

  openCategoryModal() {
    this.categoryIndex = 0;
    this.categoryAdd = '';
    this.$bvModal.show('categoryAddModal');
  }

  categoryIndexChange(e: any) {
    const category = this.participantCategory[this.categoryIndex];
    this.participantList();
  }

  async categorySave() {
    try {
      if (this.categoryAdd.length < 3) {
        this.$toast.error(`분류이름을 입력해주세요.`);
        return;
      }
      const sendData = {
        category: this.categoryAdd,
      };
      this.padding = true;
      const { data } = await this.axios.post(`/user/participantCategoryAdd`, sendData);
      this.padding = false;
      const { result } = data;
      if (result === true) {
        this.categoryIndex = 0;
        this.$toast.success(`초대하기 구분이 추가되었습니다.`);
        await this.$store.dispatch('verify');
        this.participantCategory = this.$store.getters.getParticipantCategory;
        this.$bvModal.hide('categoryAddModal');
        this.categoryOptions = this.participantCategory.map((category) => {
          return {
            text: category === 'ALL' ? '분류' : category,
            value: category === 'ALL' ? '' : category,
          };
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async favoriteChange(index: number) {
    try {
      const item = this.participantArray[index];
      const { _id, name, participantFavorite } = item;
      const { data } = await this.axios.put(`/participant/favoriteChange/${_id}`);
      const { result } = data;

      if (result === true) {
        if (!participantFavorite) {
          this.$toast.success(`[${name}]님을 즐겨찾기 등록하였습니다.`);
        } else {
          this.$toast.warning(`[${name}]님을 즐겨찾기 취소하였습니다.`);
        }
        await this.participantList();
      }
    } catch (e) {
      console.log(e);
    }
  }

  favoriteClass(index: number): string[] {
    const classArray = ['fa-star', 'fa-lg'];
    const item = this.participantArray[index];
    const { participantFavorite } = item;
    if (participantFavorite) classArray.push('fa');
    else classArray.push('far');
    return classArray;
  }

  sortChange() {
    this.sort = this.sort * -1;
    this.page = 1;
    this.participantList();
  }

  replaceGroupDetailInfo(group: any, index: number): string {
    const user = this.participantArray[index];
    const { groupTitle, projectObjectId, info } = group;
    if (info[0]) {
      const { status } = info[0];
      const { projectTitles } = user;
      const findProject = projectTitles.find((g) => g['_id'] == projectObjectId) || { title: '' };
      const { title: projectTitle } = findProject as { title: string };
      const statusText = this.PARTICIPANT.INVITATION_OPTIONS.find((i: any) => status === i.value).text || '';
      return `[${projectTitle}]${groupTitle} / ${statusText} `;
    } else {
      return '';
    }
  }

  memoModalOpen(index: number) {
    this.selectParticipant = this.participantArray[index];
    this.addMemoFlag = false;
    this.registerMemo = { memo: '' };
    this.$bvModal.show('memoModal');
  }

  closeMemoModal() {
    this.$bvModal.hide('memoModal');
  }

  async saveMemo() {
    try {
      if (this.registerMemo.memo.length < 3) {
        this.$toast.error(`등록하실 메모 내용을 입력해주세요.`);
        return;
      }

      this.padding = true;
      const sendData = { ...this.registerMemo };
      const { _id, name } = this.selectParticipant;
      const { data } = await this.axios.post(`/participant/memo/${_id}`, sendData);
      const { result } = data;

      if (result === true) {
        this.$toast.success(`[${name}] 참여자의 메모가 등록되었습니다.`);
        await this.participantList();
        this.registerMemo = { memo: '' };
        this.selectParticipant = this.participantArray.find((p) => p._id == _id) || this.selectParticipant;
      }
    } catch (e) {
      console.log(e);
    }
    this.padding = false;
    this.addMemoFlag = false;
  }

  invitation(index: number): void {
    this.selectParticipant = this.participantArray[index];
    this.invitationTargetList = [this.selectParticipant];
    this.$refs.invitationSend.registerGroupObjectId = '';
    this.$bvModal.show('invitationModal');
  }

  async sendMessage() {
    const { participantObjectId, subject, from, to, contents } = this.sendSmsForm;
    if (participantObjectId.length < 1) return true;
    else if (contents.length < 3) {
      this.$toast.error(`문자내용을 입력해주세요.`);
      return;
    } else if (!this.$validation.mobileTelCheck(to)) {
      this.$toast.error(`참여자 번호를 번호 형식에 맞게 입력해주세요.`);
      return;
    } else if (!this.$validation.mobileTelCheck(from)) {
      this.$toast.error(`발신번호를 번호 형식에 맞게 입력해주세요.`);
      return;
    }
    const sendData = {
      participantObjectId,
      subject,
      contents,
      to,
      from,
    };
    this.padding = false;
    const { data } = await this.axios.post(`/participant/sendSms`, sendData);
    this.padding = true;
    const { result } = data;
    if (result === true) {
      this.$toast.success(`문자가 발송되었습니다.`);
      this.closeSmsModal();
    }
  }

  openSmsModal(index: number): void {
    const { _id, mobile } = this.participantArray[index];
    this.sendSmsForm = {
      participantObjectId: _id || '',
      to: mobile,
      from: '0220513346',
      contents: '',
      subject: '',
    };
    this.$bvModal.show('smsModal');
  }

  closeSmsModal() {
    this.$bvModal.hide('smsModal');
  }

  async changeCategory(index: number) {
    const row: IParticipant = this.participantArray[index];
    const { _id, participantCategory, name } = row;
    const { data } = await this.axios.put(`/participant/changeCategory/participantCategory/${_id}`, {
      category: participantCategory,
    });
    const { result } = data;
    if (result === true) {
      this.$toast.success(`[${name}] 참여자의 분류가 (${participantCategory})로 변경되었습니다.`);
    }
  }

  participantModalOpen(index: number) {
    this.selectParticipant = this.participantArray[index];
    this.$refs.participantModal.open();
  }
}
