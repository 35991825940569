






































































import {Component, Watch, Vue, Prop} from "vue-property-decorator";
import { IParticipant, PARTICIPANT } from '@/interface/participant';
@Component({
})
export default class ParticipantModal extends Vue {
    @Prop() participant: IParticipant;
    modifyParticipant: IParticipant = PARTICIPANT.getInitParticipant();
    PARTICIPANT: any = PARTICIPANT;
    padding: boolean = false;
    $refs!: Vue['$refs'] & {
        modal: HTMLElement,
    }

    close(){
        this.$bvModal.hide('participantModal');
    }

    open(){
        this.$bvModal.show('participantModal');
    }

    @Watch('participant')
    watchParticipant(){
        this.modifyParticipant = { ...this.participant };
    }

    async save(){
        try{
            const {name, email, gender, birthDate, mobile} = this.modifyParticipant;
            if(name.length < 2){
                this.$toast.error(`참여자 이름은 2글자 이상 입력해주세요.`);
                return;
            }else if(this.$validation.emailCheck(email) === false){
                this.$toast.error(`참여자 이메일 형식에 맞게 입력해주세요.`);
                return;
            }else if(birthDate.length != 8){
                this.$toast.error(`참여자 생년월일(YYYYMMDD) 8글자로 입력해주세요.`);
                return;
            }else if(this.$validation.mobileTelCheck(mobile) === false){
                this.$toast.error(`참여자 모바일 형식에 맞게 입력해주세요.`);
                return;
            }
            const sendData = {
                participant: this.modifyParticipant,
            }
            this.padding = true;
            const { data } = await this.axios.put(`/participant`, sendData);
            const { result } = data;
            if(result === true ){
                this.close();
                this.$toast.success(`[${name}] 참여자가 수정되었습니다.`);
                this.$emit('callback:listLoad');
            }
            this.padding = false;
        }catch (e) {
            console.log(e);
        }
    }
}
